import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import Modal from 'react-bootstrap/Modal'
import ClientMedicalRiskForm from '../shared/client-medical-risk-form';
import ClientWellBeingForm from '../shared/client-wellbeing-observation-form';
import ClientObservationForm from '../shared/client-observation-form';
import CustomForm from 'react-validation/build/form';
import moment from "moment"
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import "./clients-view.scss";
import ClientViewTable from "../../containers/clients-view/client-view"
import { } from '../../helpers/constants';
import { toast } from '../../helpers/utility';
import Moment from 'react-moment';
// import moment from "moment"
import { DATE_FORMAT, TIME_FORMAT, LOCATION, FULL_TIME_FORMAT } from '../../helpers/constants';


class ClientViewCommunity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false, cTime: '',
            isUpdate: false,
            cell: {},
        };

        this.options = [
            { label: "By MurriWatch", value: 1 },
            { label: "By Client", value: 2 },
        ];

        this.options2 = [
            { label: "1. Cannot sit, stand or walk properly", value: 1 },
            { label: "2. Unconscious and/or unable to be woken", value: 2 },
            { label: "3. Becoming more vague and less sensible over time", value: 3 },
            { label: "4. Serious injury, including head injury, bleeding in particular from the mouth or ears", value: 4 },
            { label: "5. Other signs of injuries such as cuts or bruises", value: 5 },
            { label: "6. Cannot stop vomiting, or vomits up blood", value: 6 },
            { label: "7. Epileptic fit or fainting", value: 7 },
            { label: "8. Asthma attack", value: 8 },
            { label: "9. Difficult or noisy breathing, including cracking or wheezing", value: 9 },
            { label: "10. Chest, head, stomach, leg, arm pain", value: 10 },
            { label: "11. Diarrhea", value: 11 },
            { label: "12. Showing signs of recently taking drugs and/or inhaled fumes", value: 12 },
            { label: "13. Withdrawal symptoms – especially from alcohol (anxious, aggressive, irritable, cannot sleep, tremor’s)", value: 13 },
        ];
    }


    componentDidMount() {
        this.setState({ cell: this.props.cell, refusedBy: this.options.filter(elem => elem.value == eval(this.props.cell.serverRefusedBy)) }, () => {

        })


    }
    componentDidUpdate(prevProps) {
        if (this.props.cell && prevProps.cell !== this.props.cell) {

            this.setState({ cell: this.props.cell, refusedBy: this.options.filter(elem => elem.value == eval(this.props.cell.serverRefusedBy)) }, () => {

            })
        }


    }
    render() {




        return (
            <div className="clients-view full-width">
                <Row>
                    <Col md={12}>
                        <h4 className="text-center">
                            Community Patrol
                        </h4>
                    </Col>
                </Row>
                <Row>
                    {this.state.cell && <ClientViewTable client={this.state.cell.Client} location={this.state.cell.Location} />}
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Location </Form.Label>
                            </Col>
                            <Col md={4}>
                                {<span className="sample-texts">{this.state.cell.dropOff}</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="bg-grey d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Does the client have any belongings on them (Including medication)? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isBelonging && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.cell.isBelonging && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Is there a "safe place" contact person: </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isSafePlace && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.cell.isSafePlace && <span className="sample-texts">Yes</span>}
                            </Col>
                            <Col md={12}>
                                {this.state.cell.safePlaceDetails && <span className="sample-texts">{this.state.cell.safePlaceDetails}</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Are there any "No contact" Domestic Violence Orders in place? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isViolence && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={6}>
                                {this.state.cell.isViolence && <span className="sample-texts">Yes</span>}
                            </Col>
                            <Col md={6}>
                                <>{
                                    this.state.cell && this.state.cell.DomesiticViolances && this.state.cell.DomesiticViolances.map(violance => (
                                        <p>
                                            {
                                                violance.violancerName && violance.violancerName
                                            }
                                        </p>
                                    ))
                                }</>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Does the client have any known medical conditions? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isMedical && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.cell.isMedical && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Does an ambulance need to be contacted? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isAmbulance && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={6}>
                                {this.state.cell.isAmbulance && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">
                                    Is the client on any medication?
                                </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isOnMedication && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.cell.isOnMedication && <span className="sample-texts">Yes</span>}
                            </Col>
                            <Col md={12}>
                                <p className="sample-texts">{this.state.cell.medicationName}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Do arrangements need to be made for medication to be provided to the client?</Form.Label>
                            </Col>
                            <Col md={4}>
                                {(!this.state.cell.isArrangements || this.state.cell.isArrangements == 0) && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={4}>
                                {this.state.cell.isArrangements == 1 && <span className="sample-texts">Unknown</span>}
                            </Col>
                            <Col md={8}>
                                {this.state.cell.isArrangements == 2 && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={8}>
                        <Row className="d-flex align-items-center">
                            <Col md={6}>
                                <Form.Label className="text-label">If applicable when did the client last take their medication?</Form.Label>
                            </Col>
                            <Col md={6}>
                                <p className="sample-texts">{this.state.cell.medicationDate && moment(this.state.cell.medicationDate).format('YYYY-MM-DD')}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col md={12}>
                                <p className="sample-texts">{this.state.cell.medicationTime}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center pl-0 bg-grey">
                    <Col md={12}>
                        <div className="client-record">
                            <h6>File Notes</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">
                                    Is the client currently showing any signs of aggression e.g.
                                    spitting, swearing, hitting, punching?
                                </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isAggression && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <Form.Control name="isAggression" type="radio" id="community-checkbox60" className="custom-checkbox" />
                                {this.state.cell.isAggression && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={4}>
                        <Row className="pl-2">
                            <Form.Label className="text-label">Does the client have any of the following symptoms?</Form.Label>
                        </Row>
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col md={12}>
                                <Row className="d-flex align-items-center pl-3">
                                    <Col md={6}>

                                        <p className="sample-texts">
                                            {
                                                this.state.cell &&
                                                this.state.cell.MedicalObservations &&
                                                this.state.cell.MedicalObservations.filter(obs => obs.symptoms && obs.symptoms.length).map((obs) => (
                                                    <p>{
                                                        !eval(obs.symptoms) ? this.options2[eval(obs.symptoms)].label : this.options2[eval(obs.symptoms) - 1].label
                                                    }</p>
                                                ))
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Was service refused? </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isServerRefused && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {this.state.cell.isServerRefused && <span className="sample-texts">Yes</span>}
                            </Col>

                            <Col md={12}>
                                {this.state.refusedBy && this.state.refusedBy[0] && <span>{this.state.refusedBy[0].label}</span>}
                                {/* {this.state.cell.serverRefusedBy && this.options.filter(elem => elem.value == (eval(this.state.cell.serverRefusedBy))) && <span className="sample-texts">{this.options.filter(elem => elem.value === (eval(this.state.cell.serverRefusedBy)))[0].label}</span>} */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center p-2 bg-grey">
                    <Col md={12}>
                        <div className="client-record pl-0">
                            <h6>CLIENT RECORD</h6>
                            <p>Actions taken -Physical observations; observations of client wellbeing; who, what, when, outcome.</p>
                            <h6>Comments: &nbsp;
                                <span className="sample-texts">
                                    {this.state.cell.clientComments}
                                </span>
                            </h6>
                        </div>
                    </Col>
                </Row>
                <Row className="pl-2">
                    <Col md={12}>
                        <div className="client-record pl-0">
                            <h6>CLIENT EXIT DETAILS</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="p-2 bg-grey">
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Has a referral been made on behalf of the client </Form.Label>
                            </Col>
                            <Col md={4}>
                                {!this.state.cell.isReferral && <span className="sample-texts">No</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={8}>
                                {this.state.cell.isReferral && <span className="sample-texts">Yes</span>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="p-2 client-record">
                    <Col md={3}>
                        <Row>
                            <Col md={12}>
                                <Form.Label className="text-label">How did the client leave the service?</Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={12}>
                                {this.state.cell.isLeave == 1 && <label for="client-cell-visit9">Transported to a safe place by RCHDC</label>}
                            </Col>
                            {/*
                            <Col md={12}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <p className="sample-texts">
                                        {this.state.cell.leaveComment1}
                                    </p>
                                </Form.Group>
                            </Col>
                            */}
                            <Col md={12}>
                                {this.state.cell.isLeave == 2 && <label for="client-cell-visit10">Transported via Ambulance</label>}
                            </Col>
                            <Col md={12}>
                                {this.state.cell.isLeave == 3 && <label for="client-cell-visit11">Release from custody</label>}
                            </Col>
                            <Col md={12}>
                                {this.state.cell.isLeave == 5 && <label htmlFor="client-cell-visit12">Picked up by QPS</label>}
                            </Col>
                            <Col md={12}>
                                {this.state.cell.isLeave == 4 && <label for="client-cell-visit13">Other (please provide details)</label>}
                            </Col>
                            <Col md={12}>
                                <p className="sample-texts">
                                    {this.state.cell.leaveComment2}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="p-2 bg-grey">
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Check In </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <span className="sample-texts"> <Moment format={DATE_FORMAT}>{moment(this.state.cell.timeIn)}</Moment> <Moment format={TIME_FORMAT}>{moment(this.state.cell.timeIn)}</Moment></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Check In By </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.cell.createdCommunity && <span className="sample-texts"> {this.state.cell.createdCommunity.firstName} {this.state.cell.createdCommunity.lastName} </span>}
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className="p-2 ">
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                <Form.Label className="text-label">Check out </Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.cell.checkoutAt && <span className="sample-texts"> <Moment format={DATE_FORMAT}>{moment(this.state.cell.updatedAt)}</Moment> <Moment format={TIME_FORMAT}>{moment(this.state.cell.updatedAt)}</Moment></span>}
                                {!this.state.cell.checkoutAt && <span className="sample-texts"> Still Checked In</span>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.cell.checkoutAt && <Form.Label className="text-label">Check out By </Form.Label>}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={8}>
                                {this.state.cell.checkoutAt && <span className="sample-texts"> {this.state.cell.updatedCommunity.firstName} {this.state.cell.updatedCommunity.lastName} </span>}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
        )
    }
}

export default ClientViewCommunity;